import {
  AddRenderScene,
  RemoveRenderScene,
  GetCamera,
  SwitchProjectionMode,
  GetAnteriorArchWidth,
  GetPosteriorArchWidth,
  GetInitialTeeth,
  GetOcclusalSurface,
  SetTeethTransparency
} from './renderES';
import * as d3 from 'd3';

let TopMenu = {
  data() {
    return {
      camera: null,
      grid: {
        index: 1,
        group: null,
        ruler: null,
        // color: 'rgb(71, 98, 124)', //小网格
        color: '#969696', //小网格
        // color2: '#8c8c8c',
        color2: 'rgb(101, 103, 111)', //大网格
        rulerColor: 'rgb(255,255,255)', //标尺
        zoom: {
          large: 0.7, //大网格
          small: 7 //小网格
        },
        config: {
          1: {
            size: 300,
            divisions: 300,
            fontWidth: 3,
            text: {
              width: 300,
              height: 100,
              font: 100
            }
          },
          2: {
            size: 3000,
            divisions: 300,
            fontWidth: 10,
            text: {
              width: 300,
              height: 100,
              font: 100
            }
          },
          3: {
            size: 300,
            divisions: 3000,
            fontWidth: 1,
            text: {
              width: 350,
              height: 100,
              font: 100
            }
          }
        }
      },
      arch: {
        gTop: null,
        gBottom: null,
        color: {
          anterior: '#00FF00',
          posterior: '#0000FF'
        }
      },
      contrast: {
        gTop: null,
        gBottom: null,
        color: '#4169E1'
      },
      occlusal: {
        gTop: null,
        gBottom: null
      }
    };
  },
  watch: {
    '$store.state.biteColor': function(val) {
      this.setOcclusal();
    },
    'camera.zoom': function(val) {
      // 判断是否开启网络
      // if (this.otherOptions[5].isCheck) {
      if (this.otherOptions[6].isCheck) {
        if (val > this.grid.zoom.small && this.grid.index !== 3) {
          // 小
          this.grid.index = 3;
        } else if (val < this.grid.zoom.large && this.grid.index !== 2) {
          // 大
          this.grid.index = 2;
        } else if (val >= this.grid.zoom.large && val <= this.grid.zoom.small && this.grid.index !== 1) {
          this.grid.index = 1;
        }
        this.initGroupGrid();
      }
    }
  },
  methods: {
    initTopMenu() {
      SwitchProjectionMode(0);
      this.grid.index = 1;
      SetTeethTransparency(false);
      this.occlusalLenged = false;
    },
    /**
     * 网格
     * */
    setGroupGrid() {
      // if (this.otherOptions[5].isCheck) {
      if (this.otherOptions[6].isCheck) {
        SwitchProjectionMode(1);
        this.grid.index = 1;
        this.camera = GetCamera();
        this.initGroupGrid();
      } else {
        SwitchProjectionMode(0);
        this.removeGroupGrid();
      }
      window.camera = GetCamera();
    },
    // 容器宽,容器高,格子宽,副格倍数,颜色,ruler
    setSvgGird(width, height, w, n, color, ruler) {

      // 比例尺判定
      if (ruler == 1) {
      } else if (ruler == 2) {
        w = w * 10;
      } else if (ruler == 3) {
        w = w / 10;
      }
      // 配置
      let config = this.grid.config[ruler];
      // 刻度
      let rulerTxt = config.size / config.divisions + 'mm';
      // 移除网格
      d3.select('.scene-svg')
        .select('svg')
        .remove();
      // 创建网格容器
      var svg = d3
        .select('.scene-svg')
        .append('svg')
        .attr('width', width)
        .attr('height', height);
        
      // 比例尺位置计算
      let x1 = width / 2 - Math.ceil(width / 3 / w) * w;
      let y1 = height / 2 + Math.ceil(height / 4 / w) * w;

      // 缩放比
      var zoom = innerWidth / 1920;
      if(zoom > 1){
        x1 =x1+  Math.ceil((x1/zoom - x1)/w)*w;
        y1 =y1+  Math.ceil((y1/zoom - y1)/w)*w;
      }

      // 比例尺
      svg
        .append('line')
        .attr('x1', x1)
        .attr('y1', y1)
        .attr('x2', x1 + w)
        .attr('y2', y1)
        .attr('stroke-width', 2)
        .attr('stroke', 'rgb(26,145,255)');
      svg
        .append('line')
        .attr('x1', x1)
        .attr('y1', y1 - w / 2)
        .attr('x2', x1)
        .attr('y2', y1)
        .attr('stroke-width', 2)
        .attr('stroke', 'rgb(26,145,255)');
      svg
        .append('line')
        .attr('x1', x1 + w)
        .attr('y1', y1 - w / 2)
        .attr('x2', x1 + w)
        .attr('y2', y1)
        .attr('stroke-width', 2)
        .attr('stroke', 'rgb(26,145,255)');
      svg
        .append('text')
        .attr('x', x1)
        .attr('y', y1 + 20)
        .attr('stroke', 'rgb(26,145,255)')
        .attr('style', 'font-size:12px;')
        .text(rulerTxt);

      // 生成列
      for (var i = 0; i <= Math.ceil(width / 2 / w); i++) {
        // 左上
        svg
          .append('line')
          .attr('x1', -i * w + width / 2)
          .attr('y1', 0)
          .attr('x2', -i * w + width / 2)
          .attr('y2', height / 2)
          .attr('stroke-width', 0.5)
          .attr('stroke', color);
        svg
          .append('line')
          .attr('x1', -i * w * n + width / 2)
          .attr('y1', 0)
          .attr('x2', -i * w * n + width / 2)
          .attr('y2', height / 2)
          .attr('stroke-width', 1)
          .attr('stroke', color);
        // 左下
        svg
          .append('line')
          .attr('x1', -i * w + width / 2)
          .attr('y1', 0 + height / 2)
          .attr('x2', -i * w + width / 2)
          .attr('y2', height)
          .attr('stroke-width', 0.5)
          .attr('stroke', color);
        svg
          .append('line')
          .attr('x1', -i * w * n + width / 2)
          .attr('y1', 0 + height / 2)
          .attr('x2', -i * w * n + width / 2)
          .attr('y2', height)
          .attr('stroke-width', 1)
          .attr('stroke', color);
        if (i != 0) {
          // 右上
          svg
            .append('line')
            .attr('x1', i * w + width / 2)
            .attr('y1', 0)
            .attr('x2', i * w + width / 2)
            .attr('y2', height / 2)
            .attr('stroke-width', 0.5)
            .attr('stroke', color);
          svg
            .append('line')
            .attr('x1', i * w * n + width / 2)
            .attr('y1', 0)
            .attr('x2', i * w * n + width / 2)
            .attr('y2', height / 2)
            .attr('stroke-width', 1)
            .attr('stroke', color);
          // 右下
          svg
            .append('line')
            .attr('x1', i * w + width / 2)
            .attr('y1', 0 + height / 2)
            .attr('x2', i * w + width / 2)
            .attr('y2', height)
            .attr('stroke-width', 0.5)
            .attr('stroke', color);
          svg
            .append('line')
            .attr('x1', i * w * n + width / 2)
            .attr('y1', 0 + height / 2)
            .attr('x2', i * w * n + width / 2)
            .attr('y2', height)
            .attr('stroke-width', 1)
            .attr('stroke', color);
        }
      }

      // 生成行
      for (var i = 0; i <= Math.ceil(height / 2 / w); i++) {
        // 左上
        svg
          .append('line')
          .attr('x1', 0)
          .attr('y1', -i * w + height / 2)
          .attr('x2', width / 2)
          .attr('y2', -i * w + height / 2)
          .attr('stroke-width', 0.5)
          .attr('stroke', color);
        svg
          .append('line')
          .attr('x1', 0)
          .attr('y1', -i * w * n + height / 2)
          .attr('x2', width / 2)
          .attr('y2', -i * w * n + height / 2)
          .attr('stroke-width', 0.5)
          .attr('stroke', color);
        // 右上
        svg
          .append('line')
          .attr('x1', 0 + width / 2)
          .attr('y1', -i * w + height / 2)
          .attr('x2', width)
          .attr('y2', -i * w + height / 2)
          .attr('stroke-width', 0.5)
          .attr('stroke', color);
        svg
          .append('line')
          .attr('x1', 0 + width / 2)
          .attr('y1', -i * w * n + height / 2)
          .attr('x2', width)
          .attr('y2', -i * w * n + height / 2)
          .attr('stroke-width', 0.5)
          .attr('stroke', color);
        if (i != 0) {
          // 左下
          svg
            .append('line')
            .attr('x1', 0)
            .attr('y1', i * w + height / 2)
            .attr('x2', width / 2)
            .attr('y2', i * w + height / 2)
            .attr('stroke-width', 0.5)
            .attr('stroke', color);
          svg
            .append('line')
            .attr('x1', 0)
            .attr('y1', i * w * n + height / 2)
            .attr('x2', width / 2)
            .attr('y2', i * w * n + height / 2)
            .attr('stroke-width', 0.5)
            .attr('stroke', color);
          // 右下
          svg
            .append('line')
            .attr('x1', 0 + width / 2)
            .attr('y1', i * w + height / 2)
            .attr('x2', width)
            .attr('y2', i * w + height / 2)
            .attr('stroke-width', 0.5)
            .attr('stroke', color);
          svg
            .append('line')
            .attr('x1', 0 + width / 2)
            .attr('y1', i * w * n + height / 2)
            .attr('x2', width)
            .attr('y2', i * w * n + height / 2)
            .attr('stroke-width', 0.5)
            .attr('stroke', color);
        }
      }
    },
    initGroupGrid() {
      let dom = document.getElementsByClassName('scene')[0];
      let svg = document.getElementsByClassName('scene-svg')[0];
      let width = dom.clientWidth;
      let height = dom.clientHeight;
      svg.height = height;

      this.setSvgGird(width, height, 10 * this.camera.zoom, 10, 'rgba(101,103,111,.5)', this.grid.index);
      // this.removeGroupGrid();
      // this.creatGroupGrid(this.grid.index);
      // this.camera.add(this.grid.group);
      // //旋转监听
      // this.loop();
    },
    loop() {
      requestAnimationFrame(this.loop);
      this.renderLoad();
    },
    removeGroupGrid() {
      var svg = d3.select('.scene-svg').select('svg');
      svg.remove();
      // if (this.grid.group) {
      //   this.camera.remove(this.grid.group);
      //   cancelAnimationFrame(this.loop);
      // }
    },
    creatGroupGrid(flag) {
      this.grid.group = new THREE.Group();
      this.grid.group.name = 'grid';
      let config = this.grid.config[flag];

      let grid = new THREE.GridHelper(config.size, config.divisions, this.grid.color, this.grid.color);
      let grid2 = new THREE.GridHelper(config.size, config.divisions / 10, this.grid.color2, this.grid.color2);
      this.grid.group.add(grid);
      this.grid.group.add(grid2);
      // 刻度
      this.creatRuler(config);
      this.grid.group.position.set(0, 0, -100);
      this.grid.group.rotateX(Math.PI / 2);
    },
    creatRuler(config) {
      this.grid.ruler = new THREE.Group();
      let color = this.grid.rulerColor;
      // 刻度
      const arrLine = [
        [
          [0, 0],
          [0 - config.size / config.divisions, 0]
        ],
        [
          [0, 0 - (config.size / config.divisions) * 0.25],
          [0, 0]
        ],
        [
          [0 - config.size / config.divisions, 0 - (config.size / config.divisions) * 0.25],
          [0 - config.size / config.divisions, 0]
        ]
      ];
      arrLine.forEach(val => {
        var geometry = new THREE.Geometry();
        geometry.vertices.push(new THREE.Vector3(val[0][0], 0.2, val[0][1]));
        geometry.vertices.push(new THREE.Vector3(val[1][0], 0.2, val[1][1]));
        var linex = new THREE.Line(
          geometry,
          new THREE.LineBasicMaterial({
            color: color,
            // linewidth: 2,
            opacity: 0.45
          })
        );
        this.grid.ruler.add(linex);
      });
      var mesh = this.setGroupGridText(config, config.size / config.divisions + 'mm', color);
      this.grid.ruler.add(mesh);
      this.grid.group.add(this.grid.ruler);
    },
    setGroupGridText(config, text, color) {
      // console.log(config);
      var geometry = new THREE.Geometry();
      var p1 = new THREE.Vector3(0, 0.5, 0);
      var p2 = new THREE.Vector3(0 + config.fontWidth, 0.5, 0);
      var p3 = new THREE.Vector3(0 + config.fontWidth, 0.5, 0 - config.fontWidth / 2);
      var p4 = new THREE.Vector3(0, 0.5, 0 - config.fontWidth / 2);
      geometry.vertices.push(p1, p2, p3, p4);
      var normal = new THREE.Vector3(0, 0, 1);
      var face0 = new THREE.Face3(0, 1, 2, normal);
      var face1 = new THREE.Face3(0, 2, 3, normal);
      geometry.faces.push(face0, face1);
      var t0 = new THREE.Vector2(0, 0);
      var t1 = new THREE.Vector2(1, 0);
      var t2 = new THREE.Vector2(1, 1);
      var t3 = new THREE.Vector2(0, 1);
      geometry.faceVertexUvs[0].push([t0, t1, t2], [t0, t2, t3]);

      let material = new THREE.MeshBasicMaterial({
        map: new THREE.CanvasTexture(
          this.setGroupTextCanvas(text, color, config.text.width, config.text.height, config.text.font)
        ),
        transparent: true,
        opacity: 1,
        color: 0xffffff
      });
      let mesh = new THREE.Mesh(geometry, material);
      return mesh;
    },
    /**
     * 旋转监听
     */
    renderLoad() {
      // if (!this.otherOptions[5].isCheck) {
      if (!this.otherOptions[6].isCheck) {
        return false;
      }
      var obj = this.camera.toJSON().object;
      if (obj.zoom > this.grid.zoom.small && this.grid.index !== 3) {
        // 小
        this.grid.index = 3;
        this.initGroupGrid();
      } else if (obj.zoom < this.grid.zoom.large && this.grid.index !== 2) {
        // 大
        this.grid.index = 2;
        this.initGroupGrid();
      } else if (obj.zoom >= this.grid.zoom.large && obj.zoom <= this.grid.zoom.small && this.grid.index !== 1) {
        this.grid.index = 1;
        this.initGroupGrid();
      }

      if (this.grid.index === 2) {
        this.grid.ruler.position.x = Math.round(((1 / obj.zoom) * obj.left * 0.8) / 10) * 10;
        this.grid.ruler.position.z = -Math.round(((1 / obj.zoom) * obj.bottom * 0.8) / 10) * 10;
      } else if (this.grid.index === 3) {
        this.grid.ruler.position.x = ((1 / obj.zoom) * obj.left * 0.8).toFixed(1);
        this.grid.ruler.position.z = -((1 / obj.zoom) * obj.bottom * 0.8).toFixed(1);
      } else {
        this.grid.ruler.position.x = Math.round((1 / obj.zoom) * obj.left * 0.8);
        this.grid.ruler.position.z = -Math.round((1 / obj.zoom) * obj.bottom * 0.8);
      }
    },
    /**
     * 牙弓宽度
     */
    setArch() {
      // if (this.otherOptions[3].isCheck) {
      if (this.otherOptions[7].isCheck) {
        this.initArch();
      } else {
        this.removeArch();
      }
    },
    initArch() {
      if (this.arch.gTop) {
        RemoveRenderScene(this.arch.gTop);
      }
      if (this.panTop) {
        this.arch.gTop = new THREE.Group();
        this.setArchLine(this.arch.gTop, GetAnteriorArchWidth(true, this.curstep), this.arch.color.anterior);
        this.setArchLine(this.arch.gTop, GetPosteriorArchWidth(true, this.curstep), this.arch.color.posterior);
        AddRenderScene(this.arch.gTop);
      }
      if (this.arch.gBottom) {
        RemoveRenderScene(this.arch.gBottom);
      }
      if (this.panBottom) {
        this.arch.gBottom = new THREE.Group();
        this.setArchLine(this.arch.gBottom, GetAnteriorArchWidth(false, this.curstep), this.arch.color.anterior);
        this.setArchLine(this.arch.gBottom, GetPosteriorArchWidth(false, this.curstep), this.arch.color.posterior);
        AddRenderScene(this.arch.gBottom);
      }
    },
    removeArch() {
      if (this.panTop && this.arch.gTop) {
        RemoveRenderScene(this.arch.gTop);
      }
      if (this.panBottom && this.arch.gBottom) {
        RemoveRenderScene(this.arch.gBottom);
      }
    },
    setArchLine(group, arrObj, color) {
      if (arrObj.length === 0) {
        return;
      }
      var geometry = new THREE.Geometry();
      arrObj.forEach(obj => {
        geometry.vertices.push(new THREE.Vector3(obj.x, obj.y, obj.z));
      });
      var line = new THREE.Line(geometry, new THREE.MeshBasicMaterial({ color: color }));
      line.geometry.verticesNeedUpdate = true;

      group.add(line);
      group.add(this.createShapePoints(arrObj, color));
      group.add(this.createLineDistance(arrObj, color));
    },
    createShapePoints(arrObj, color) {
      let canvas = document.createElement('canvas');
      canvas.width = 100;
      canvas.height = 100;
      let context = canvas.getContext('2d');
      context.fillStyle = color;
      //canvas 创建圆
      context.arc(50, 50, 45, 0, 2 * Math.PI);
      context.fill();
      // 创建材质
      let texture = new THREE.Texture(canvas);
      texture.needsUpdate = true;
      let geometry = new THREE.Geometry();
      // 点位置
      arrObj.forEach(obj => {
        geometry.vertices.push(new THREE.Vector3(obj.x, obj.y, obj.z));
      });
      let material = new THREE.PointsMaterial({
        color: color,
        size: 10,
        transparent: true,
        map: texture
      });
      let points = new THREE.Points(geometry, material);
      return points;
    },
    createLineDistance(arrObj, color) {
      var spriteMaterial = new THREE.SpriteMaterial({
        map: new THREE.CanvasTexture(
          this.setGroupTextCanvas(this.getArchLineDistance(arrObj) + 'mm', color, 500, 100, 100)
        ), //设置精灵纹理贴图
        transparent: true,
        color: color //设置精灵矩形区域颜色
      });
      // 创建精灵模型对象，不需要几何体geometry参数
      var sprite = new THREE.Sprite(spriteMaterial);
      // 控制精灵大小，比如可视化中精灵大小表征数据大小
      sprite.scale.set(7, 2, 1);
      sprite.position.x = (arrObj[1].x + arrObj[0].x) / 2;
      sprite.position.y = (arrObj[1].y + arrObj[0].y) / 2;
      sprite.position.z = (arrObj[1].z + arrObj[0].z) / 2 - 1;
      return sprite;
    },
    getArchLineDistance(arrObj) {
      return Math.sqrt(
        Math.pow(arrObj[1].x - arrObj[0].x, 2) +
          Math.pow(arrObj[1].y - arrObj[0].y, 2) +
          Math.pow(arrObj[1].z - arrObj[0].z, 2)
      ).toFixed(2);
    },
    setGroupTextCanvas(text, color, width, height, font) {
      let canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      let ctx = canvas.getContext('2d');
      ctx.fillStyle = 'rgba(255, 255, 255, 0)';
      ctx.fillRect(0, 0, width, height);
      ctx.font = font + 'px " bold';
      ctx.fillStyle = color;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(text, width / 2, (height + 5) / 2);
      // console.log(canvas.toDataURL('image/png'));
      return canvas;
    },
    /**
     * 叠加
     */
    setContrast() {
      // if (this.otherOptions[6].isCheck) {
      if (this.otherOptions[0].isCheck) {
        this.initContrast();
      } else {
        this.removeContrast();
      }
    },
    initContrast() {
      if (this.contrast.gTop) {
        RemoveRenderScene(this.contrast.gTop);
      }
      var material = new THREE.MeshBasicMaterial({
        color: this.contrast.color,
        transparent: true,
        opacity: 0.2,
        polygonOffset: true,
        polygonOffsetFactor: -1,
        polygonOffsetUnits: -1
      });
      if (this.panTop) {
        this.contrast.gTop = new THREE.Mesh(GetInitialTeeth(true), material);
        AddRenderScene(this.contrast.gTop);
      }
      if (this.contrast.gBottom) {
        RemoveRenderScene(this.contrast.gBottom);
      }
      if (this.panBottom) {
        this.contrast.gBottom = new THREE.Mesh(GetInitialTeeth(false), material);
        AddRenderScene(this.contrast.gBottom);
      }
    },
    removeContrast() {
      if (this.panTop && this.contrast.gTop) {
        RemoveRenderScene(this.contrast.gTop);
      }
      if (this.panBottom && this.contrast.gBottom) {
        RemoveRenderScene(this.contrast.gBottom);
      }
    },
    /**
     * 咬合面
     */
    setOcclusal() {
      // if (this.otherOptions[7].isCheck) {
      if (this.otherOptions[5].isCheck) {
        if (this.panTop && this.panBottom) {
          SetTeethTransparency(true);
        } else {
          SetTeethTransparency(false);
        }
        this.occlusalLenged = true;
        this.initOcclusal();
      } else {
        this.occlusalLenged = false;
        this.removeOcclusal();
        SetTeethTransparency(false);
      }
    },
    initOcclusal() {
      if (this.occlusal.gTop) {
        RemoveRenderScene(this.occlusal.gTop);
      }
      if (this.occlusal.gBottom) {
        RemoveRenderScene(this.occlusal.gBottom);
      }
      this.clearOcclusal(); // 孙懿修改
      this.occlusal.gTop = new THREE.Group();
      this.occlusal.gBottom = new THREE.Group();
      GetOcclusalSurface(this.curstep).forEach(obj => {
        // mesh depth jaw
        // 0~0.3 #99e602; 0.3~0.6 #ff6600; >0.6 #a60100
        let color;
        if (obj.depth <= 0.3) {
          color = this.$store.state.biteColor.split(',')[0];
        } else if (obj.depth > 0.6) {
          color = this.$store.state.biteColor.split(',')[1];
        } else {
          color = this.$store.state.biteColor.split(',')[2];
        }
        let material = new THREE.MeshBasicMaterial({
          color: color,
          polygonOffset: true,
          polygonOffsetFactor: -1,
          polygonOffsetUnits: -1
        });
        if (obj.jaw) {
          this.occlusal.gTop.add(new THREE.Mesh(obj.mesh, material));
        } else {
          this.occlusal.gBottom.add(new THREE.Mesh(obj.mesh, material));
        }
      });
      if (this.panTop) {
        AddRenderScene(this.occlusal.gTop);
      }
      if (this.panBottom) {
        AddRenderScene(this.occlusal.gBottom);
      }
    },
    removeOcclusal() {
      if (this.panTop && this.occlusal.gTop) {
        RemoveRenderScene(this.occlusal.gTop);
      }
      if (this.panBottom && this.occlusal.gBottom) {
        RemoveRenderScene(this.occlusal.gBottom);
      }
    },
    clearOcclusal() { // 孙懿修改
      if (this.occlusal.gTop) {
        this.occlusal.gTop.children.forEach((self) => {
          if (self && self.isMesh) {
            self.geometry.dispose();
          }
        });
      }
      if (this.occlusal.gBottom) {
        this.occlusal.gBottom.children.forEach((self) => {
          if (self && self.isMesh) {
            self.geometry.dispose();
          }
        });
      }
    }
  }
};

export default TopMenu;
