import {
  GetRenderScene,
  GetIPRData,
  GetToothIdPosition,
  GetTeethId,
  GetIPRPosition,
  SetAttachmentTransparency, // 孙懿修改
  GetCamera,
  GetCallback,
  GetOcclusalSurface,
  AddRenderScene,
  RemoveRenderScene
} from './renderES';
window.mouse = {
  x: null,
  y: null
};
let menuClickMixin = {
  methods: {
    /**
     * 编号按钮点击事件
     */
    numberClick() {
      // if (this.otherOptions[1].isCheck) {
      if (this.otherOptions[2].isCheck) {
        SetAttachmentTransparency(true); // 孙懿修改
        let arrs = GetTeethId();
        let vm = this;
        arrs.forEach(item => {
          if (+item < 30 && this.panTop) {
            //上颌显示并且牙齿是上颌
            let poss = GetToothIdPosition(item, vm.planIndext);
            let numberMesh = vm.addNumberMesh(poss, item);
            vm.numberMeshList.up.push(numberMesh);
          }
          if (+item > 30 && this.panBottom) {
            let poss = GetToothIdPosition(item, vm.planIndext);
            let numberMesh = vm.addNumberMesh(poss, item);
            vm.numberMeshList.bottom.push(numberMesh);
          }
        });
      } else {
        this.clearNumberMesh();
        SetAttachmentTransparency(false); // 孙懿修改
      }
    },
    /**
     * 清理牙编号模型
     */
    clearNumberMesh() {
      let scene = GetRenderScene();
      this.numberMeshList.up.forEach(item => {
        RemoveRenderScene(item);
      });
      this.numberMeshList.bottom.forEach(item => {
        RemoveRenderScene(item);
      });
      this.numberMeshList.up = [];
      this.numberMeshList.bottom = [];
    },
    /**
     * 追加牙编号模型
     */
    addNumberMesh(poss, text) {
      let scene = GetRenderScene();
      var geometry = new THREE.Geometry();
      var p1 = new THREE.Vector3(poss[0].x, poss[0].y, poss[0].z);
      var p2 = new THREE.Vector3(poss[1].x, poss[1].y, poss[1].z);
      var p3 = new THREE.Vector3(poss[2].x, poss[2].y, poss[2].z);
      var p4 = new THREE.Vector3(poss[3].x, poss[3].y, poss[3].z);
      geometry.vertices.push(p1, p2, p3, p4);
      var normal = new THREE.Vector3(0, 0, 1);
      var face0 = new THREE.Face3(0, 1, 2, normal);
      var face1 = new THREE.Face3(0, 2, 3, normal);
      geometry.faces.push(face0, face1);
      var t0 = new THREE.Vector2(0, 0);
      var t1 = new THREE.Vector2(1, 0);
      var t2 = new THREE.Vector2(1, 1);
      var t3 = new THREE.Vector2(0, 1);
      var uv1 = [t0, t1, t2];
      var uv2 = [t0, t2, t3];
      geometry.faceVertexUvs[0].push(uv1, uv2);
      let vm = this;
      var material = new THREE.MeshBasicMaterial({
        map: new THREE.CanvasTexture(vm.getTextCanvas(text)),
        transparent: true,
        opacity: 1,
        color: 0xffffff
      });
      var mesh = new THREE.Mesh(geometry, material);
      AddRenderScene(mesh);
      return mesh;
    },
    /**
     * 生成牙编号图片
     */
    getTextCanvas(text, width = 50, height = 50, color = 'rgba(255, 255, 255, 0)') {
      var canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      var ctx = canvas.getContext('2d');
      ctx.fillStyle = color;
      ctx.fillRect(0, 0, width, height);
      ctx.font = 40 + 'px bold';
      ctx.fillStyle = '#000000';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(text, width / 2, height / 2 + 5);
      return canvas;
    },
    /**
     * 片切点击事件
     */
    iprClick() {
      let iprCallback = GetCallback();
      this.clearIprMesh();
      // if (this.otherOptions[2].isCheck) {
      if (this.otherOptions[3].isCheck) {
        iprCallback.callback = this.iprHover;
        if (this.panTop) {
          //上颌显示
          this.addIprMesh(true);
        }
        if (this.panBottom) {
          //下颌显示
          this.addIprMesh(false);
        }
      } else {
        iprCallback.callback = null;
      }
    },
    clearIprMesh() {
      this.iprMeshLish.forEach(item => {
        RemoveRenderScene(item);
      });
      this.iprMeshLish = [];
    },
    /**
     * 生成片切图片
     */
    getIprCanvas(text, color = 'rgba(255, 255, 255, 0)') {
      var canvas = document.createElement('canvas');
      var ctx = canvas.getContext('2d');
      var canvasText = { fontSize: 40 };
      var metrics = ctx.measureText(text);
      var h = Math.round(canvasText.fontSize * 2 + 40);
      var w = Math.round(metrics.width * 7);
      canvas.width = w;
      canvas.height = h;
      ctx.font = canvasText.fontSize + 'px bold';
      ctx.fillStyle = color;
      const step = 1 / w;
      ctx.beginPath();
      ctx.moveTo(w, h / 2);
      for (let i = 0; i < 2 * Math.PI; i += step) {
        ctx.lineTo(w / 2 + (w / 2) * Math.cos(i), h / 2 + (h / 2) * Math.sin(i));
      }
      ctx.closePath();
      ctx.fill();

      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillStyle = '#000000';
      ctx.fillText(text, w / 2, h / 2 + 5);
      return canvas;
    },
    addIprMesh(flag) {
      let iprs = GetIPRData(flag);
      let vm = this;
     // iprs.forEach((item,index) => {

      for(let i = iprs.length -1;i>=0;i--){
        let item = iprs[i];
        
        let points = GetIPRPosition(item.toothid1, item.toothid2, vm.planIndext); // 孙懿修改
        //画线
        const material = new THREE.LineBasicMaterial({
          color: '#1b91ff'
        });
        const linePoints = [];
        linePoints.push(new THREE.Vector3(points[0].x, points[0].y, points[0].z));
        linePoints.push(new THREE.Vector3(points[1].x, points[1].y, points[1].z));
        const geometry = new THREE.BufferGeometry().setFromPoints(linePoints);
        const line = new THREE.Line(geometry, material);
        let color = '#ffffff';
        if (item[3] == vm.planIndext) {
          color = '#1a95eb';
        } else if (item[3] > vm.planIndext) {
          color = '#959595';
        }
        let texture = new THREE.Texture(this.getIprCanvas(item.ipr.toFixed(2), color));

        let spriteMate = new THREE.SpriteMaterial({
          map: texture,
          transparent: true, 
          color:'#ffffff',
          side: THREE.DoubleSide, 
          depthWrite: true,
          alphaTest: 0.55 // 属性值（从0到1）。如果某个像素的alpha值小于该值，那么该像素不会显示出来。可以使用这个属性移除一些与透明相关的毛边
        });

        texture.anisotropy = 2048;
        texture.needsUpdate = true;
        let textObj = new THREE.Sprite(spriteMate);
 
        textObj.scale.set(5, 4, 1);
        textObj.position.set(points[1].x, points[1].y, points[1].z);
        textObj.name = `在牙齿${item.toothid1}和${item.toothid2}之间，在${item.step}步之前有${item.ipr.toFixed(2)}mm`;
        line.name = `在牙齿${item.toothid1}和${item.toothid2}之间，在${item.step}步之前有${item.ipr.toFixed(2)}mm`;
        vm.iprMeshLish.push(line);
        vm.iprMeshLish.push(textObj);
        AddRenderScene(line);
        AddRenderScene(textObj);
      }

      /*
      for(let i = iprs.length -1;i>=0;i--){
        let item = iprs[i];
        
        let points = GetIPRPosition(item.toothid1, item.toothid2, vm.planIndext); // 孙懿修改
        //画线
        let color = '#ffffff';
        if (item[3] == vm.planIndext) {
          color = '#1a95eb';
        } else if (item[3] > vm.planIndext) {
          color = '#959595';
        }
        let texture = new THREE.Texture(this.getIprCanvas(item.ipr.toFixed(2), color));

        let spriteMate = new THREE.SpriteMaterial({
          map: texture,
          transparent: true, 
          color:'#ffffff',
          side: THREE.DoubleSide, 
          depthWrite: false,
          depthTest: true,
        });

        texture.anisotropy = 2048;
        texture.needsUpdate = true;
        let textObj = new THREE.Sprite(spriteMate);
        textObj.scale.set(5, 4, 1);
        textObj.position.set(points[1].x, points[1].y+0.1, points[1].z);

        vm.iprMeshLish.push(textObj);
        AddRenderScene(textObj);
      }
      */
     // });
    },
    /**
     * 片切鼠标滑入事件
     */
    iprHover() {
      let scene = GetRenderScene();
      let camera = GetCamera();
      console.log(mouse)
      // 通过鼠标点的位置和当前相机的矩阵计算出raycaster
      raycaster.setFromCamera(mouse, camera);
      // 获取raycaster直线和所有模型相交的数组集合
      var intersects = raycaster.intersectObjects(scene.children);
      let name = intersects[0]?.object?.name;
      // name = name && name.length>0 ? name : null
      if (name) {
        if (name === 'tip') {
          return;
        }
        let canvas = getTextCanvas(name, 800, 80, 'rgba(255, 255, 255, 0.75)');
        let texture = new THREE.Texture(canvas);
        texture.anisotropy = 2048;
        texture.needsUpdate = true;
        let spriteMate = new THREE.SpriteMaterial({ map: texture,    
          depthWrite: true,
          depthTest:false });
        let textObj = new THREE.Sprite(spriteMate);
        textObj.scale.set(30, 3.45, 1);
        
        let vec = intersects[0].point;
        textObj.position.set(vec.x, vec.y-3, vec.z + 3);
        textObj.name = "tip";
        if (tipObj) {
          RemoveRenderScene(tipObj);
          tipObj = textObj;
          AddRenderScene(tipObj);
        } else {
          tipObj = textObj;
          AddRenderScene(tipObj);
        }
        tipName = name;
      } else {
        if (tipObj) {
          RemoveRenderScene(tipObj);
          tipObj = null;
          tipName = null;
        }
      }
    },
    /**
     * 咬合面点击事件
     */
    occlusalClick() {
      this.occlusalLenged = true;
    },
    /**
     * 测量点击事件
     */
    mouseButton(event) {
      if (event.button === 1) {
        window.pointsArray = [];
        window.intersectsArray = [];
        window.window_mouse = false;
        window.allPointsArray = [];
        window.scene.remove(window.moveLine);
        window.allMeshObjectArr.forEach(item => {
          RemoveRenderScene(item);
        });
        window.allMeshObjectArr = [];
        document.removeEventListener('mousemove', window.onMouseMove, false);
        document.getElementsByClassName('scene')[0].onclick = null;
        window.moveLine = null;
        // this.otherOptions[4].isCheck = false;
        this.otherOptions[8].isCheck = false;
        document.getElementsByClassName('scene')[0].onmousedown = null;
      }
    }
  }
};

export default menuClickMixin;
function getTextCanvas(text, width = 50, height = 50, color = 'rgba(255, 255, 255, 0)') {
  var canvas = document.createElement('canvas');
  canvas.width = width + 15;
  canvas.height = height + 15;
  var ctx = canvas.getContext('2d');
  ctx.fillStyle = color;
  ctx.lineWidth = 5;
  ctx.strokeStyle = '#ffffff';
  ctx.fillRect(0, 0, width + 10, height + 15);
  ctx.font = 40 + 'px " bold';
  ctx.fillStyle = '#000000';
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  ctx.fillText(text, width / 2, height / 2 + 12);
  //画圆弧
  // ctx.strokeStyle = '#ffffff';
  // ctx.moveTo(20, 1); //创建一个起点
  // ctx.lineTo(750, 1); // 创建一个水平线
  // ctx.arcTo(814, 1, 814, 10, 20); // 创建一个弧
  // ctx.lineTo(814, 10); // 继续垂直线
  // ctx.arcTo(814, 94, 700, 94, 20);
  // ctx.lineTo(100, 94);
  // ctx.arcTo(1, 94, 1, 30, 20);
  // ctx.arcTo(1, 1, 30, 1, 20);
  ctx.stroke();
  return canvas;
}
const setMousePoint = function(event) {
  var canves = document.getElementsByClassName('scene')[0];
  let innerWidth = canves.width;
  let innerHeight = canves.height;
  // 增加缩放比
  let offsetX = event.offsetX * window.devicePixelRatio;
  let offsetY = event.offsetY * window.devicePixelRatio;

  mouse.x = (offsetX / innerWidth) * 2 - 1;
  mouse.y = -(offsetY / innerHeight) * 2 + 1;
};
function getPoint(event) {
  let raycaster = new THREE.Raycaster();
  setMousePoint(event);

  raycaster.setFromCamera(window.mouse, window.camera);

  let intersects = raycaster.intersectObjects(window.scene.children);
  if (intersects.length > 0) {
    for (let i = 0; i < intersects.length; i++) {
      if (intersects[i].object.type === 'Mesh') {
        return intersects[i].point;
      }
    }
    // point 即点击坐标
  }
}
function onDocumentMouseMove(event) {
  const scene = document.getElementsByClassName('scene')[0];
  window.mouse.x = (event.offsetX*window.devicePixelRatio / scene.width) * 2 - 1;
  window.mouse.y = -(event.offsetY*window.devicePixelRatio / scene.height) * 2 + 1;
  // mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
  // mouse.y = -((event.clientY - 145) / (window.innerHeight - 270)) * 2 + 1;
}
document.addEventListener('mousemove', onDocumentMouseMove, false); //监听鼠标移动事件
window.tipObj = null;
window.tipName = null;

/* 获取射线与平面相交的交点 */
function getIntersects(event) {
  setMousePoint(event);
  var raycaster = new THREE.Raycaster();
  var normal = new THREE.Vector3(0, 1, 0);
  /* 创建平面 */
  var planeGround = new THREE.Plane(normal, 0);
  /* 从相机发出一条射线经过鼠标点击的位置 */
  raycaster.setFromCamera(window.mouse, window.camera);
  /* 获取射线 */
  var ray = raycaster.ray;
  /* 计算相机到射线的对象，可能有多个对象，返回一个数组，按照距离相机远近排列 */
  var intersects = ray.intersectPlane(planeGround, new THREE.Vector3());
  /* 返回向量 */
  return intersects;
}
window.pointsArray = [];
window.intersectsArray = [];
window.window_mouse = false;
window.allPointsArray = [];
window.allMeshObjectArr = [];
/* 鼠标按下事件 */
window.onMouseDown = function(event) {

   
  /* 获取相机发出的射线与 Plane 相交点*/
  var intersects = getPoint(event);
  /* 存放网格的三维坐标 */
  /* 鼠标左键按下时，创建点和线段 */
  if (event.button === 0) {
    if (!window_mouse) {
      document.addEventListener('mousemove', onMouseMove, false);
      /* 依据 windwo_mouse 标识避免事件的重复添加 */
      window_mouse = true;
    }
    if (typeof intersects == 'undefined') {
      return;
    }
    /* 若交点此时在平面之内则创建点（Points） */
    var pointsGeometry = new THREE.BufferGeometry();
    const pArray = new Array();
    pArray.push(intersects);
    pointsGeometry.setFromPoints(pArray);
    var pointsMaterial = new THREE.PointsMaterial({
      color: '#351ed0',
      size: 3
    });
    var points = new THREE.Points(pointsGeometry, pointsMaterial);
    intersectsArray.push(intersects);
    pointsArray.push(points);
    /* 创建线段 */
    var lineGeometry = new THREE.BufferGeometry();
    var lineMaterial = new THREE.LineBasicMaterial({
      color: '#351ed0',
      depthTest: false
    });
    if (intersectsArray.length == 2) {
      let positions = [];
      for (var i = 0; i < intersectsArray.length; i++) {
        positions.push(intersectsArray[i].x);
        positions.push(intersectsArray[i].y);
        positions.push(intersectsArray[i].z);
      }
      lineGeometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
      var line = new THREE.Line(lineGeometry, lineMaterial);

      window.scene.add(line);
      allMeshObjectArr.push(line);
      let size = createLineDistance(intersectsArray, '#351ed0');
      window.scene.add(size);
      allMeshObjectArr.push(size);
      pointsArray.shift();
      intersectsArray.shift();
    }
    window.scene.add(points);
    allMeshObjectArr.push(points);
    allPointsArray.push(points);
    if (allPointsArray.length === 2) {
      document.removeEventListener('mousemove', onMouseMove, false);
      document.getElementsByClassName('scene')[0].onclick = null;
      window.scene.remove(window.moveLine);
      window.moveLine = null;
      window.menuData[4].isCheck = false;
      window_mouse = false;
      if (scene.getObjectByName('line_move')) {
        scene.remove(scene.getObjectByName('line_move'));
        /* 删除数组中的元素，否则的话再次重绘会链接之前的点接着重绘 */
        pointsArray.shift();
      }
    }
  }
};
/* 鼠标移动事件 */
window.moveLine = null;
window.onMouseMove = function(event) {
  var intersects = getIntersects(event);
  if (typeof intersects == 'undefined') {
    return;
  }
  if (intersectsArray.length > 0) {
    if (intersectsArray[0] != intersects) {
      dynamicLine(intersectsArray[0], intersects);
    }
  }
};
function dynamicLine(p1, obj) {
  // window.scene.remove(window.moveLine);
  let activeLine = window.moveLine;
  if (activeLine) {
    let positions = [];
    positions.push(p1.x, p1.y, p1.z);
    positions.push(obj.x, obj.y, obj.z);
    activeLine.geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
    activeLine.geometry.verticesNeedUpdate = true;
  } else {
    activeLine = createLine(p1, obj);
    // activeLine.name = 'active-line';
    window.moveLine = activeLine;
    window.scene.add(activeLine);
  }
}
function createLine(
  p1,
  p2,
  config = {
    color: '#351ed0'
  }
) {
  let lineGeometry = new THREE.BufferGeometry();
  let lineMaterial = new THREE.LineBasicMaterial({
    color: config.color,
    depthTest: false
  });
  let positions = [];
  positions.push(p1.x);
  positions.push(p1.y);
  positions.push(p1.z);
  positions.push(p2.x);
  positions.push(p2.y);
  positions.push(p2.z);
  lineGeometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
  let line = new THREE.Line(lineGeometry, lineMaterial);
  return line;
}

/**
 * 圖片精靈
 */
function createLineDistance(arrObj, color) {
  var spriteMaterial = new THREE.SpriteMaterial({
    map: new THREE.CanvasTexture(setGroupTextCanvas(getArchLineDistance(arrObj) + 'mm', color, 160)), //设置精灵纹理贴图
    transparent: true,
    color: color, //设置精灵矩形区域颜色
    depthTest: false
  });
  // 创建精灵模型对象，不需要几何体geometry参数
  var sprite = new THREE.Sprite(spriteMaterial);
  // 控制精灵大小，比如可视化中精灵大小表征数据大小
  sprite.scale.set(7, 2, 1);
  sprite.position.x = (arrObj[1].x + arrObj[0].x) / 2;
  sprite.position.y = (arrObj[1].y + arrObj[0].y) / 2;
  sprite.position.z = (arrObj[1].z + arrObj[0].z) / 2 - 1;
  return sprite;
}
function getArchLineDistance(arrObj) {
  return Math.sqrt(
    Math.pow(arrObj[1].x - arrObj[0].x, 2) +
      Math.pow(arrObj[1].y - arrObj[0].y, 2) +
      Math.pow(arrObj[1].z - arrObj[0].z, 2)
  ).toFixed(2);
}
function setGroupTextCanvas(text, color, width) {
  let height = 40;
  let canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  let ctx = canvas.getContext('2d');
  ctx.fillStyle = 'rgba(255, 255, 255, 0)';
  ctx.fillRect(0, 0, width, height);
  ctx.font = 30 + 'px " bold';
  ctx.fillStyle = color;
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  ctx.fillText(text, width / 2, height / 2);
  return canvas;
}

// window.onresize = function(){
//   if (document.getElementsByClassName('icon-style')){
//     document.getElementsByClassName('icon-style')[0].style.right = document.getElementsByClassName('right-menu-drawer')[0].querySelector('.el-drawer').style.width;
//   }
// }